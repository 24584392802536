/**
 *
 * Manage products filter in a products list
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/* global _, DEBUG, handlebarsTemplates, zg_sortElements, zgCreateFilterObject, zgGetObjectPropertyValue, zgParseString, zgSearchString */

/**
 * @event document#updatedFilters Filter are updated
 * @type {array}
 * @property {int} categoryId - Actual Category Id
 * @property {object} appliedFilters - Object with applied filters
 */

/**
 * @event document#applyFilters Filter was applied
 * @type {array}
 * @property {object} appliedFilters - Object with applied filters
 * @property {array} filteredItems - Array with objects of product rendered
 * @property {array} filteredKeys - Array with list of product id rendered
 */

/**
 * @event document#filters.renderFilters Filter rendered
 * @type {array}
 * @property {string} element - Html element
 * @property {object} filters - Object with all filters
 * @property {string} appliedFilters - Object with selected filters
 * @property {string} filtersContainers - Html element of filter container
 */

/**
 * @event document#click.zg.filters.applyFilter Click to filter value
 * @type {null}
 */

/**
 * @event document#click.zg.filters.resetFilters Click to filter reset button
 * @type {null}
 */

/**
 * @event document#zg.urimgr.updatedUri  The url has been update
 * @type {object}
 * @property {string} base - Url without get parameters
 * @property {object} components - Object with all components of the url
 * @property {string} hash - Hash value
 * @property {int} index - TO CHECK
 * @property {object} status - Object with all data (clearUrl,options,pid, ecc ecc)
 */

(function ( $, _ ) {
	'use strict';
	/**
	 * @param {string} [backendFiltering] The collection is filtered in backend and filters are re-generated backend side every time a filter is selected. True or List of back-end generated filters. This is supposed to be inherited from getContentCMS or (in the future) getProductInfo
	 * @param {string} [createFilters] create filters Object based on a list of field names from the CMS  The properties are case sensitive.
	 * @param {string} [createFiltersByPattern] Any property name that matches the pattern ( regular expression ) will be used to create filters. If no field name includes the pattern or the field is already set in the normal filters or in 'createFilters' this won't do anything.
	 * @param {string} [filtersContainer]
	 * @param {string} [filtersOptions]
	 * @param {string} [applyFilters] re-apply all filters.
	 * @param {string} [itemsContainer]
	 * @param {string} [filterElement] Element (link) with value to filter
	 * @param {string} [resetFilters] remove all applied filters.
	 * @param {array} [exclusiveFilters] Exclusive filter means that you can only select one option for that filter (selecting an option removes the previous ones). Can be set it as true (all filters exclusive) or pass an array with the id for the filters you want to be exclusive
	 * @param {string} [initial]
	 * @param {boolean} [renderItems] render the items using pagination after filtering. Set as false if the rendering will be done by another script.
	 * @param {string} [templateFilterList] handlebars template to create list of filters
	 * @param {string} [templateFilterReset] handlebars template to create reset filter button
	 * @param {string} [templateFilterSearch] handlebars template to create filter search
	 * @param {string} [templateFilterSlider] handlebars template to create filter slider (for example for price)
	 * @param {boolean} [filterValueItemsCount] Show the items count for each of the filter values (How many items would be available for that filter if it was selected?) set as false to avoid executing the code (better performance)
	 * @param {boolean} [hideEmptyFilterValues] Hide the 'empty' filter values. Set as false if you don't want the 'empty' filters to have a specific class.
	 * @param {boolean} [enableReset] set to false if you don't want to create the reset element
	 * @param {boolean} [enableSearch] set to false if you don't want to create the search element
	 * @param {string} [searchField]
	 * @param {string} [searchInFields]
	 * @param {string} [searchTypeaheadFields]
	 * @param {int} [sliderMinDiff] minimum difference between the min and max values to create a slider
	 * @param {int} [sliderStep] step of slider filter
	 * @param {array} [sortFilters] true will sort alphabetically. you can set an array with the specific order instead
	 * @param {string} [sortFiltersBy] property used to sort the filters
	 * @param {boolean} [updateUri]
	 * @param {boolean} [isFirstLoad] don't update url at first load
	 */
	var DEFAULTS = {
		backendFiltering: false,
		createFilters: null,
		createFiltersByPattern: null,

		filtersContainer: null,

		filtersOptions: null,

		applyFilters:   '[data-zg-role="apply-filters"]',
		itemsContainer: '[data-zg-role="pagination"]',
		filterElement:  '[data-zg-action]',
		resetFilters:   '[data-zg-role="reset-filters"]',
		exclusiveFilters: false,
		initial: {},
		renderItems: true,

		templateFilterList:   'filter-list',
		templateFilterReset:  'filter-reset',
		templateFilterSearch: 'filter-search',
		templateFilterSlider: 'filter-slider',

		filterValueItemsCount: true,
		hideEmptyFilterValues: true,
		enableReset: true,

		enableSearch:   false,
		searchField:    '[data-zg-role="filter-search"]',
		searchInFields: null,

		searchTypeaheadFields: false,

		sliderMinDiff: 50,
		sliderStep:    10,

		sortFilters:   true,
		sortFiltersBy: 'id',

		updateUri:   false,
		isFirstLoad: true,

		filtersSelected: false
	};


	// FILTERS CLASS DEFINITION
	// ========================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var Filters = function ( element, options ) {
		this.$element = $( element );

		this.options = _.clone( DEFAULTS );
		this.updateOptions( options );

		this.filters         = {};
		this.originalFilters = {};

		this.items = [];

		this.filteredItems = [];
		this.filteredKeys  = [];

		this.appliedFilters   = {};
		this.availableFilters = [];

		// exclusive frontend filters, like the search and prices
		this.extraFilters = [];

		this.cachedFilters = {};

		this.applyFiltersTimeout = null;

		this.$initialContainer  = this.options.filtersContainer ? $( this.options.filtersContainer ) : this.$element;
		this.$filtersContainers = this.$initialContainer;

		this.$itemsContainer = $( this.options.itemsContainer );

		this.__setGeneralEventHandlers();
	};


	/**
	 * @method addFilter
	 * @fires document#updatedFilters Filters was update
	 *
	 * @param {string}    action
	 * @param {string}    filter
	 * @param {string}    value
	 * @param {?boolean=} overwrite
	 */
	Filters.prototype.addFilter = function ( action, filter, value, overwrite ) {
		var index;

		if ( action && filter ) {

			this.options.isFirstLoad = false;

			if ( action === 'filter' && value ) {
				if ( !this.appliedFilters[filter] || _.isEmpty( this.appliedFilters[filter] ) || overwrite ) {

					// there is no option selected for that filter yet
					this.appliedFilters[filter] = [value];

				} else {

					index = _.indexOf( this.appliedFilters[filter], value );

					if ( index === -1 ) { // the value is not selected
						if ( this.__isExclusiveFilter( filter ) ) {
							// The filter is exclusive: Selecting an option removes the previous ones
							this.appliedFilters[filter] = [value];
						} else {
							// normal filter: add the value
							this.appliedFilters[filter].push( value );
						}
					} else {
						// the value is currently selected. Remove it
						this.appliedFilters[filter].splice( index, 1 );
					}
				}

				if ( _.isEmpty( this.appliedFilters[filter] ) ) {
					// remove the filter if it has no values.
					this.appliedFilters[filter] = null;
				} else {
					// make sure the filters values are consistent
					this.appliedFilters[filter] = _.uniq( this.appliedFilters[filter] );
				}

			} else if ( action === 'reset' ) {

				// reset that filter
				this.appliedFilters[filter] = null;

			}

			// do the actual filtering
			if ( this.__isBackendFilter( filter ) ) {
				this.applyFiltersBackEnd();
			} else {
				this.applyFilters();
			}

			$( document ).trigger( 'updatedFilters', [this.options.categoryId, this.appliedFilters] );
		}
	};


	/**
	 * @method applyFilters
	 * @fires document#applyFilters Filters are applied (and the product are rendered)
	 *
	 * @param {boolean=} stopUriUpdate - true if the url shouldn't be updated regardless of the script options
	 */
	Filters.prototype.applyFilters = function ( stopUriUpdate ) {
		var that = this;

		this.$itemsContainer.addClass( 'loading' );

		clearTimeout( this.applyFiltersTimeout );
		this.applyFiltersTimeout = setTimeout( function () {
			var filteredItems = that.processFilters() || {};

			that.filteredItems = filteredItems.items || [];
			that.filteredKeys  = filteredItems.keys || [];

			// update the filters interface
			that.__processFilterObject();

			// update the url
			if ( !stopUriUpdate ) {
				that.__updateURL();
			}

			// render the products
			that.renderItems();

			that.$itemsContainer.removeClass( 'loading' );

			that.$element.trigger( 'applyFilters', [that.appliedFilters, that.filteredItems, that.filteredKeys] );
			$( document ).trigger( 'applyFilters', [that.$element, that.appliedFilters, that.filteredItems, that.filteredKeys] );
		}, 10 );
	};


	/**
	 *
	 */
	Filters.prototype.applyFiltersBackEnd = function () {
		this.$itemsContainer.addClass( 'loading' );
		this.$filtersContainers.addClass('loading');

		this.__updateURL();

		this.$element.trigger( 'zg.filter.applyFilters', [this.appliedFilters] );
	};


	/**
	 *
	 * @param {string}        filter
	 * @param {string|number} value
	 *
	 * @returns {number}
	 */
	Filters.prototype.countFilterValueItems = function ( filter, value ) {
		var productIds = this.filters[filter].values[value].products || [];

		// what would be the result if the filter value was to be applied
		var filterValueItems = this.getItemsForFilterValue( filter, value );

		var intersection = _.intersection( filterValueItems.keys, productIds );

		return intersection.length;
	};


	/**
	 *
	 * @param {string}  filterId
	 * @param {Object=} data
	 * @param {string}  template
	 *
	 * @returns {*}
	 */
	Filters.prototype.createFilter = function ( filterId, data, template ) {
		var $filter;

		switch ( filterId ) {
			case 'price':
				if ( (data.max - data.min) >= this.options.sliderMinDiff ) {
					$filter = this.createSlider( filterId, handlebarsTemplates.render( template || this.options.templateFilterSlider, data ), data );
				} else if ( DEBUG ) {
					console.info( 'price filter not created: Price difference under minimum limit' );
				}
				break;

			case 'reset':
				$filter = $( handlebarsTemplates.render( template || this.options.templateFilterReset ) );
				break;

			case 'search':
				$filter = $( handlebarsTemplates.render( template || this.options.templateFilterSearch, data ) );
				break;

			default:
				$filter = $( handlebarsTemplates.render( template || this.options.templateFilterList, data ) );
		}

		if ( $filter ) {
			$filter.data( this.options.sortFiltersBy, filterId );
		}

		return $filter;
	};


	/**
	 *
	 * @param {string}             filterId
	 * @param {string|HTMLElement} item - html String created by handlebars
	 * @param {Object}             data - filter information
	 *
	 * @returns {*|HTMLElement}
	 */
	Filters.prototype.createSlider = function ( filterId, item, data ) {
		var that        = this,
            selRange    = '[type="range"]',
			minVal      = Math.floor( data.min / 10 ) * 10,
			maxVal      = Math.ceil( data.max / 10 ) * 10,
			initial     = [
				that.appliedFilters[filterId + '-min'] && _.isNumber( +that.appliedFilters[filterId + '-min'][0] ) ?
					+that.appliedFilters[filterId + '-min'][0] : minVal,
				that.appliedFilters[filterId + '-max'] && _.isNumber( +that.appliedFilters[filterId + '-max'][0] ) ?
					+that.appliedFilters[filterId + '-max'][0] : maxVal
			],
			$item       = $( item ),
			$filterInfo = $( '.slider-value', $item ),
			$slider     = $( '[data-role="rangeslider"]', $item),
            $ranges     = $( selRange, $slider);

        // Set attr to the two inputs
        $ranges.attr({
            min:    minVal,
            max:    maxVal,
            step:   that.options.sliderStep
        });
        $ranges.each(function(i) {
            $( this ).attr({ value: initial[i] });
        });

		function sliderHandler ( values ) {
			that.appliedFilters[filterId + '-min'] = ( values[0] > minVal ) ? [values[0]] : null;
			that.appliedFilters[filterId + '-max'] = ( values[1] < maxVal ) ? [values[1]] : null;

			that.applyFilters();
		}

		function sliderText ( values ) {
			$filterInfo.html(
				window.renderPrice ( values[0] ) +
				' &nbsp;&nbsp; - &nbsp;&nbsp; ' +
				window.renderPrice ( values[1] )
			);
		}

        // Init jquery mobile rangeslider
        $slider.rangeslider({
            create: function( event, ui ) {}
        });

        $slider.on( 'mouseup touchend', function (){
            var ranges = $slider.find( selRange ),
                values = [
                    $( ranges[0] ).val(),
                    $( ranges[1] ).val()
                ];
            sliderText( values );
            sliderHandler( values );
        } );
				$slider.bind('reset',function() {
						var ranges = $slider.find( selRange ),
								values = [
									minVal,maxVal
										//$( ranges[0] ).val(),
										//$( ranges[1] ).val()
								];
						sliderText( values );
						sliderHandler( values );
				});
				

		sliderText( initial );

		return $item;
	};

	/**
	 *
	 * @param {Object} appliedFilters
	 *
	 * @returns {string}
	 */
	Filters.prototype.getCacheKey = function ( appliedFilters ) {
		var cacheKey = [];

		_.each( appliedFilters || {}, function ( values, key ) {
			if ( _.isArray( values ) && values.length ) {
				cacheKey.push( String( key ) + ':' + values.sort().join(',') );
			}
		}, this );

		return cacheKey.sort().join('_');
	};


	/**
	 *
	 * @param {string}          filter
	 * @param {?string|number=} value
	 *
	 * @returns {{items: Array, keys: Array}}
	 */
	Filters.prototype.getItemsForFilterValue = function ( filter, value ) {
		var appliedFilters = _.clone( this.appliedFilters );

		if ( value ) {
			if ( !appliedFilters[filter] || this.__isExclusiveFilter( filter ) ) {
				appliedFilters[filter] = [value];
			} else {
				appliedFilters[filter] = _.union( appliedFilters[filter], [value] );
				_.uniq( appliedFilters[filter] );
			}
		} else {
			appliedFilters[filter] = null;
		}

		// what would be the result if the filter was to be applied
		return this.processFilters( appliedFilters );
	};


	/**
	 *
	 * @param {string}        filter
	 * @param {string|number} value
	 *
	 * @returns {boolean}
	 */
	Filters.prototype.isEmptyFilterValue = function ( filter, value ) {
		var isEmpty;
		var productIds;
		var filterValueItems;

		if ( this.options.hideEmptyFilterValues ) {
			productIds = this.filters[filter].values[value].products || [];

			// what would be the result if the filter value was to be applied
			filterValueItems = this.getItemsForFilterValue( filter, value );

			isEmpty = !_.some(filterValueItems.keys, function ( id ) {
				return _.contains( productIds, id );
			}, this);
		} else {
			isEmpty = false;
		}

		return isEmpty;
	};


	/**
	 *
	 * @param {Array=} appliedFilters
	 *
	 * @returns {{items: Array, keys: Array}}
	 */
	Filters.prototype.processFilters = function ( appliedFilters ) {
		var isFilterApplied = false;
		var productIds      = null;
		var cacheKey;
		var result;

		if ( !appliedFilters ) {
			appliedFilters = this.appliedFilters;
		}

		cacheKey = this.getCacheKey( appliedFilters );

		if ( this.cachedFilters[cacheKey] ) {
			result = this.cachedFilters[cacheKey];
		} else {
			_.each( appliedFilters, function ( filterValues, filterKey ) {
				var filtered = false; // should the current filter be applied?
				var temp = [];

				if (
					this.filters.hasOwnProperty( filterKey ) &&
					!this.__isBackendFilter( filterKey ) &&
					_.isArray( filterValues )
				) {
					_.each( filterValues, function ( value ) {
						if ( this.filters[filterKey].values[value] ) {
							temp     = temp.concat( this.filters[filterKey].values[value].products || [] );
							filtered = true; // yes it should  :)
						}
					}, this );

					if ( filtered ) {
						isFilterApplied = true; // The collection has normal filters applied

						if ( productIds === null ) {
							productIds = temp;
						} else {
							productIds = _.intersection( productIds, temp );
						}
					}
				}
			}, this );

			// make sure the ids are unique
			if ( productIds ) {
				productIds = _.uniq( productIds );
			}

			result = {
				items: [],
				keys:  []
			};

			_.each( this.items, function ( item ) {
				if (
					( !isFilterApplied || _.contains( productIds, item.id ) ) &&
					this.__checkSearchString( item, appliedFilters ) &&
					this.__checkItemsPrice( item, appliedFilters )
				) {
					// The item includes the searchString
					// and is between the minimum and maximum price
					result.items.push( item );
					result.keys.push( item.id );
				}
			}, this );

			this.cachedFilters[cacheKey] = result;
		}

		return result;
	};


	/**
	 * @method renderFilters
	 * @fires document#filters.renderFilters Filters rendered
	 *
	 */
	Filters.prototype.renderFilters = function () {
		var $containers = [],
			containers = {
				defaultContainer: []
			};

		// destroy the current filters
		this.$filtersContainers
			.empty()
			.addClass('loading' )
			.off( '.zg.filters.applyFilter' );

		// create normal filters
		_.each( this.filters, function ( filter, filterId ) {
			this.__renderSingleFilter( filterId, filter, containers );
		}, this );

		// create search
		if ( this.options.enableSearch ) {
			//filters = $.merge( this.createFilter( 'search', this.appliedFilters.search ), filters );
			this.__renderSingleFilter( 'search', {
				value: this.appliedFilters.search,
				typeahead: this.__createSearchTypeahead()
			}, containers );
		}

		// create reset
		if ( this.options.enableReset ) {
			//$.merge( filters, this.createFilter( 'reset' ) );
			this.__renderSingleFilter( 'reset', null, containers );
		}

		_.each( containers, function ( filtersArray, containerSelector ) {
			var $container = this.$initialContainer;

			// sort options
			if ( this.options.sortFilters ) {
				filtersArray.sort( zg_sortElements( {
					attr:              this.options.sortFiltersBy,
					pattern:           _.isArray( this.options.sortFilters ) ? this.options.sortFilters : null,
					avoidNumbersOnTop: true
				} ) );
			}

			if ( containerSelector !== 'defaultContainer' ) {
				$container = $(containerSelector);
			}

			$container.html( filtersArray ).removeClass('loading');

			$containers = $.merge( $container, $containers );
		}, this );

		// remove the loading class from the previous containers
		this.$filtersContainers.removeClass('loading');

		this.$filtersContainers = $containers;
		this.__setEventHandlers();

		$( document ).trigger( 'filters.renderFilters', [this.$element, this.filters, this.appliedFilters, this.$filtersContainers] );
	};


	/**
	 *
	 * @param {Array=} items
	 */
	Filters.prototype.renderItems = function ( items ) {
		var collection;

		var that = this;



		if ( this.options.renderItems ) {
			collection = items || this.filteredItems;

			if($.isEmptyObject( that.appliedFilters )){
				that.options.filtersSelected = false;
			}else{
				that.options.filtersSelected = true;
			}


			//console.log($.isEmptyObject( that.appliedFilters ));
			//console.log( that.options.filtersSelected );

			this.$itemsContainer.zg_pagination( this.options, collection );
		}
	};


	/**
	 * This one should be kinda self explanatory...  ;)
	 */
	Filters.prototype.resetFilters = function () {
		this.appliedFilters = {};

		this.__updateURL();

		if ( this.options.backendFiltering ) {
			this.applyFiltersBackEnd();
		} else {
			this.applyFilters();
		}
	};


	/**
	 *
	 * @param {Object} filters
	 */
	Filters.prototype.setAppliedFilters = function ( filters ) {
		var filter;
		var values;

		this.appliedFilters = {};

		for ( filter in filters ) {
			if (
				filters.hasOwnProperty( filter ) &&
				_.contains( this.availableFilters, filter )
			) {
				values = [];

				if ( _.isArray( filters[filter] ) ) {
					values = filters[filter];
				} else if ( _.isString( filters[filter] ) ) {
					values = filters[filter].split( ',' );
				} else if ( _.isNumber( filters[filter] ) ) {
					values = [filters[filter]];
				}

				this.appliedFilters[filter] = values;
			}
		}
	};


	/**
	 *
	 */
	Filters.prototype.setItems = function ( collection ) {
		if ( _.isObject( collection ) ) {
			collection = _.toArray( collection );
		}

		this.items = collection;
	};


	/**
	 *
	 * @param {Object=} filters
	 * @param {Array=}  items
	 * @param {string=} url
	 */
	Filters.prototype.updateFilters = function ( filters, items, url ) {
		if ( items ) {
			this.setItems( items );
		}

		if ( filters || this.options.createFilters || this.options.createFiltersByPattern ) {
			this.filters = filters;

			this.__createCustomFilters();

			this.originalFilters = this.filters;

			this.__setAvailableFilters();
			this.__setInitialFilters();
		}

		this.applyFilters( true );

		if ( url ) {
			this.__updateURL( url, true );
		}
	};


	/**
	 * Overwrite the current options with new values
	 *
	 * @param {Object} options
	 */
	Filters.prototype.updateOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );

		if ( _.isString( this.options.sortFilters ) ) {
			this.options.sortFilters = this.options.sortFilters.split( ',' );
		}

		if ( _.isString( this.options.searchInFields ) ) {
			this.options.searchInFields = this.options.searchInFields.split( ',' );
		}
	};


	// FILTERS PRIVATE METHODS
	// =======================


	/**
	 * check if the item price is between the min and max values
	 *
	 * @param {Object}  item
	 * @param {Object=} appliedFilters
	 * @returns {boolean}
	 * @private
	 */
	Filters.prototype.__checkItemsPrice = function ( item, appliedFilters ) {
		var filters = appliedFilters || this.appliedFilters,
			valid   = true;

		if (
			(
				filters['price-max'] &&
				filters['price-max'][0] &&
				_.isNumber( +filters['price-max'][0] ) &&
				item.price.sell > filters['price-max'][0]
			) || (
				filters['price-min'] &&
				filters['price-min'][0] &&
				_.isNumber( +filters['price-min'][0] ) &&
				item.price.sell < filters['price-min'][0]
			)
		) {
			// product price is higher than the maximum or  lower than the minimum
			valid = false;
		}

		return valid;
	};


	/**
	 * check if the item includes the search string
	 *
	 * @param {Object}  item
	 * @param {Object=} appliedFilters
	 * @returns {boolean}
	 * @private
	 */
	Filters.prototype.__checkSearchString = function ( item, appliedFilters ) {
		var filters = appliedFilters || this.appliedFilters,
			valid   = true;

		if ( filters.search ) {
			valid = zgSearchString( filters.search, item, this.options.searchInFields );
		}

		return valid;
	};


	/**
	 * Extend the original filters with with custom frontend filters
	 *
	 * @param {Array=}       collection
	 * @param {Object|null=} createFilters
	 * @param {string|null=} createFiltersByPattern
	 * @param {Object=}      initial
	 * @private
	 */
	Filters.prototype.__createCustomFilters = function ( collection, createFilters, createFiltersByPattern, initial ) {
		this.filters = zgCreateFilterObject(
			collection || this.items,
			createFilters || this.options.createFilters,
			createFiltersByPattern || this.options.createFiltersByPattern,
			initial || this.filters
		);
	};


	/**
	 *
	 * @returns {Array}
	 * @private
	 */
	Filters.prototype.__createSearchTypeahead = function () {
		var typeahead = [];

		if ( this.options.searchTypeaheadFields && _.isArray(this.options.searchTypeaheadFields) && this.options.searchTypeaheadFields.length) {
			_.each( this.filteredItems, function ( item ) {
				var values = [];

				_.each( this.options.searchTypeaheadFields, function ( prop ) {
					var value = zgGetObjectPropertyValue(item, prop);

					if ( value ) {
						values.push( value );
					}
				}, this );

				if ( values.length ) {
					typeahead.push( values.join(', ') );
				}
			}, this );
		}

		return _.uniq( typeahead ).sort();
	};


	/**
	 *
	 * @param {string} filterId
	 * @returns {Object}
	 * @private
	 */
	Filters.prototype.__getFilterOptions = function ( filterId ) {
		var options = {};

		if ( this.options.filtersOptions && _.isObject( this.options.filtersOptions[filterId] ) ) {
			options = this.options.filtersOptions[filterId];
		}

		return options;
	};


	/**
	 * Returns true is the current filter is exclusive (selecting one option un-selects the others)
	 *
	 * @param {string} filter
	 * @returns {boolean}
	 * @private
	 */
	Filters.prototype.__isBackendFilter = function ( filter ) {
		var backend = false;

		if (
			this.options.backendFiltering === true ||
			( _.isArray( this.options.backendFiltering ) && _.contains( this.options.backendFiltering, filter ) )
		) {
			// The current filter is managed in backend
			backend = true;
		}

		return backend;
	};


	/**
	 * Returns true is the current filter is exclusive (selecting one option un-selects the others)
	 *
	 * @param {string} filter
	 * @returns {boolean}
	 * @private
	 */
	Filters.prototype.__isExclusiveFilter = function ( filter ) {
		var exclusive = false;

		if (
			this.options.exclusiveFilters === true ||
			( _.isArray( this.options.exclusiveFilters ) && _.contains( this.options.exclusiveFilters, filter ) )
		) {
			// The current filter is exclusive (or all of them are).
			exclusive = true;
		}

		return exclusive;
	};


	/**
	 *
	 * @param {string} filter
	 * @returns {boolean}
	 * @private
	 */
	Filters.prototype.__isValidFilter = function ( filter ) {
		var valid = false;

		if ( filter ) {
			if (
                (this.filters && this.filters.hasOwnProperty( filter )) ||
                _.contains( this.extraFilters, filter )
            ) {
				valid = true;
			}
		}

		return valid;
	};


	/**
	 * Update the filters object, setting the current filters as active
	 *
	 * @private
	 */
	Filters.prototype.__processFilterObject = function () {
		var filterObject, originalFilters;

		if ( this.options.createFilters ) {
			filterObject    = [];
			originalFilters = {};

			// get the original filter for the applied filters and recalculate it for the empty ones
			_.each( this.options.createFilters, function ( item ) {
				if (
					( item.id && this.appliedFilters[item.id] ) ||
					( item.property && this.appliedFilters[item.property] )
				) {
					originalFilters[item.id || item.property] = this.originalFilters[item.id || item.property];
				} else {
					filterObject.push( item );
				}
			}, this );

			this.__createCustomFilters( this.filteredItems, filterObject );
			_.extend( this.filters, originalFilters );
		}

		_.each( this.filters, function ( filter, filterKey ) {
			if ( filterKey !== 'price' ) {
				filter.isActive  = false;
				filter.isVisible = false;
				filter.selectedValues = this.appliedFilters[filterKey] || '';
				filter.selectedValuesNames = [];
				filter.resetItemsCount = false;

				if ( this.__isBackendFilter( filterKey ) ) {
					filter.isVisible = !_.isEmpty(filter.values);

					_.each( this.appliedFilters[filterKey], function ( valueKey ) {
						if ( filter.values && filter.values[valueKey] ) {
							filter.isActive = true;

							filter.selectedValuesNames.push( filter.values[valueKey].name );

							filter.values[valueKey].isActive = true;
						}
					}, this );

				} else {
					_.each( filter.values, function ( value, valueKey ) {
						var count;

						value.isActive   = false;
						value.isEmpty    = false;
						value.itemsCount = false;

						if ( this.options.filterValueItemsCount ) {

							count = this.countFilterValueItems( filterKey, valueKey );

							value.itemsCount = count;

							// is the filter value empty ( it wouldn't select any item ) ?
							value.isEmpty = ( count === 0 );

						} else if ( this.options.hideEmptyFilterValues ) {

							// We still have to check if the value is empty, but we don't need to show the numbers.
							// We use a better performing function
							value.isEmpty = this.isEmptyFilterValue( filterKey, valueKey );

						}

						if ( this.appliedFilters[filterKey] && _.contains( this.appliedFilters[filterKey], valueKey ) ) {

							value.isActive = true;

							// one of the values is selected for the filter
							filter.isActive = true;

							// and obviously it means that there is a visible element
							filter.isVisible = true;

							filter.selectedValuesNames.push( filter.values[valueKey].name );

						} else if ( !value.isEmpty ) {

							filter.isVisible = true;

						}
					}, this );

				}

				// items for the 'reset' filter field
				if ( this.options.filterValueItemsCount ) {
					if ( filter.isActive ) {
						filter.resetItemsCount = this.getItemsForFilterValue( filter, null ).keys.length;
					} else {
						filter.resetItemsCount = this.filteredKeys.length;
					}
				}
			}
		}, this );

		this.renderFilters();
	};


	/**
	 *
	 * @param filterId
	 * @param filterObject
	 * @param containers
	 * @private
	 */
	Filters.prototype.__renderSingleFilter = function ( filterId, filterObject, containers ) {
		var $item,
			containerId,
			filterOptions;

		if ( filterId && containers ) {
			if ( filterObject ) {
				filterObject.id = filterId;
			}

			filterOptions = this.__getFilterOptions( filterId );
			containerId = filterOptions.container || 'defaultContainer';

			$item = this.createFilter( filterId, filterObject, filterOptions.template );

			if ( $item ) {

				if ( !containers[containerId] ) {
					containers[containerId] = [];
				}

				$.merge( containers[containerId], $item );

			} else {

				console.warn( 'Invalid filter', filterId );

			}
		}
	};


	/**
	 * Create an array with the keys for the filters that could be applied to the collection.
	 * This is used to manage the urls push and pop.
	 *
	 * @private
	 */
	Filters.prototype.__setAvailableFilters = function () {
		var filter;

		this.availableFilters = [];

		for ( filter in this.filters ) {
			if ( this.filters.hasOwnProperty( filter ) ) {
				if ( filter === 'price' ) {
					this.availableFilters.push( 'price-max' );
					this.availableFilters.push( 'price-min' );

					this.extraFilters.push( 'price-max' );
					this.extraFilters.push( 'price-min' );
				} else {
					this.availableFilters.push( filter );
				}
			}
		}

		if ( this.options.enableSearch ) {
			this.availableFilters.push( 'search' );
			this.extraFilters.push( 'search' );
		}

		// this will reset the pagination when a new filter is selected.
		this.availableFilters.push( 'page' );

		this.availableFilters.sort();

		if ( _.isArray( this.options.backendFiltering ) ) {
			this.availableFilters = _.union( this.availableFilters, this.options.backendFiltering );
		}
	};


	/**
	 * @method __setEventHandlers
	 * @listen filtersContainers#click.zg.filters.applyFilter Click on filter value
	 */



	Filters.prototype.__setEventHandlers = function () {
		var that = this;

		this.$filtersContainers.off( '.zg.filters.applyFilter' );

		// -------------------------------------------------------------------------

		this.$filtersContainers.on( 'click.zg.filters.applyFilter', this.options.filterElement, function ( e ) {
			var $this, data;

			$this = $( this );

			if ( !$this.is( 'select' ) && !$this.is( 'option' ) && !$this.is( 'input' ) ) {
				e.preventDefault();

				data = $this.data();

				that.addFilter( data.zgAction, data.filter, '' + data.value );
			}
		} );

		this.$filtersContainers.on( 'change.zg.filters.applyFilter', this.options.filterElement, function ( e ) {
			var $this, action, filter, value;

			e.preventDefault();

			$this  = $( this );
			value  = '' + $this.val();
			action = value ? $this.data( 'zg-action' ) : 'reset';
			filter = $this.data( 'filter' );

			that.addFilter( action, filter, value, true );
		} );

		// -------------------------------------------------------------------------

		this.$filtersContainers
			.find( this.options.searchField )
			.off( '.zg.filters.applyFilter' )
			.on( 'change.zg.filters.applyFilter select.zg.filters.applyFilter', function ( e ) {
				var value;

				e.preventDefault();
				clearTimeout( that.searchTimer );

				value = zgParseString( $( this ).val(), true );

				if ( value !== (that.appliedFilters.search || [] )[0] ) {
					that.appliedFilters.search = value ? [value] : null;

					that.applyFilters();
				}
			} );
	};


	/**
	 * @method __setGeneralEventHandlers
	 * @listen document#click.zg.filters.resetFilters Click su filter reset
	 */

	/**
	 * @method __setGeneralEventHandlers
	 * @listen document#zg.urimgr.updatedUri The url is updated
	 */

	Filters.prototype.__setGeneralEventHandlers = function () {
		var that = this;

		// -------------------------------------------------------------------------

		$( document ).on( 'click.zg.filters.applyFilter', this.options.applyFilters, function ( e ) {
			e.preventDefault();
			that.applyFilters();
		} );

		$( document ).on( 'click.zg.filters.resetFilters', this.options.resetFilters, function ( e ) {
			e.preventDefault();
			that.resetFilters();
		} );

		// -------------------------------------------------------------------------

		$( document ).on( 'zg.urimgr.updatedUri', function ( e, info ) {
			var applyFilters = false,
				components,
				i,
				filter;

			that.options.isFirstLoad = false;

			components = info.components || {};

			for ( i = 0; i < that.availableFilters.length && !applyFilters; i++ ) {
				filter = that.availableFilters[i];

				if ( that.__isValidFilter( filter ) && !_.isEqual( components[filter], that.appliedFilters[filter] ) ) {
					applyFilters = true;
				}
			}

			if ( applyFilters ) {
				that.setAppliedFilters( components );
				that.applyFilters( true );
			}
		} );
	};


	/**
	 *
	 */
	Filters.prototype.__setInitialFilters = function () {
		var appliedFilters = $.extend(
			{},
			this.options.initial || {},
			this.appliedFilters || {}
		);

		this.setAppliedFilters( appliedFilters );

		// reset initial filters
		this.options.initial = {};
	};


	/**
	 *
	 * @param {string=}  url
	 * @param {boolean=} replace
	 * @private
	 */
	Filters.prototype.__updateURL = function ( url, replace ) {
		var request;

		if ( this.options.updateUri && !this.options.isFirstLoad ) {
			request = {
				applied:   this.appliedFilters,
				available: this.availableFilters,
				data:      { categoryId: +(this.options.categoryId) }
			};

			if ( url ) {
				request.url = url;
			}

			if ( replace ) {
				request.action = 'replace';
			}

			$.uriMgr( request );
		}

		// Not the first load anymore
		this.options.isFirstLoad = false;
	};


	// FILTERS PLUGIN DEFINITION
	// =========================

	/**
	 *
	 * @param option
	 * @param filters
	 * @param items
	 * @param url
	 *
	 * @returns {*}
	 */
	function Plugin ( option, filters, items, url ) {
		return this.each( function () {
			var $this   = $( this ),
				data    = $this.data( 'zg.filters' ),
				options = _.extend( {}, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			filters = filters || options.filters || null;
			items   = items || options.items || null;

			if ( !data ) {
				$this.data( 'zg.filters', (data = new Filters( this, options )) );
			} else if ( typeof option === 'object' ) {
				data.updateOptions( option );
			}

			data.updateFilters( filters, items, url );
		} );
	}

	$.fn.zg_filters             = Plugin;
	$.fn.zg_filters.Constructor = Filters;

}( jQuery, _ ));
