/**
 * Allows to refund or exchange products from an order
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event.
 * @property {string} message - The error message.
 */

/**
 * @event document#zg-notification Generic notification. Used by 2002-zg-notifier.js to display the notification
 * @type {object}
 * @property {string} eventType - Typology of event.
 * @property {string} message - The error message.
 */

/**
 * @event document#return.sentRequest Ajax request of return/change product return success
 * @type {null}
 */



(function ( $, _ ) { /* global _, JS_TRANSLATIONS, DEBUG, handlebarsTemplates */
	'use strict';

	/**
	 * @selector data-zg-role="return" The plugin start for each selector in the dom when the page load
	 */

	var selector = '[data-zg-role="return"]';

	// RETURN CLASS DEFINITION
	// =======================

	var Return = function ( element, options ) {
		this.$element = $( element );
		this.options = options;

		this.exchangeProducts = [];
		this.refundProducts = [];
		this.bankDetails = {};

		this.enableProceed = false;
		this.showRefundMsg = false;
		this.showExchangeMsg = false;

		this.sentExchangeRequest = false;
		this.sentRefundRequest = false;

		this.setEventHandlers();
	};


	/**
	 * @param {int} orderId Id of the order
	 * @param {string} [productContainer] Container of the product to return
	 * @param {string} [returnSelector] Select where the client decide if want return or change option for the product
	 * @param {string} [returnAction] Container of actions part of return (reasons select) or change (options select)
	 * @param {string} [successClass] css class for success in product Container
	 * @param {string} [dangerClass] css class if there is some problem
	 * @param {string} [productUpdater] Button open the popup for make a change
	 * @param {string} [combination] ul where display new options selected (for change)
	 * @param {string} [productStatus] select with reason for returning
	 * @param {string} [productMessage] textarea with message with additional notes
	 * @param {string} [bankDetails] form with bank details (if the order)
	 * @param {string} [bankDetailsHolder] input text for Account holder
	 * @param {string} [bankDetailsIban] input text for iban
	 * @param {string} [bankDetailsSwift] input text for swift
	 */

	Return.DEFAULTS = {
		orderId: null,

		productContainer: '[data-zg-role="return-product"]',
		returnSelector: '[data-zg-role="return-select"]',
		returnAction: '[data-zg-role="return-action"]',

		successClass: 'panel-success',
		dangerClass: 'panel-danger',

		// exchange
		productUpdater: '[data-zg-role="quickedit"]',
		combination: '[data-zg-role="change-combination"]',

		// refund
		productStatus: '[data-zg-role="refund-status-code"]',
		productMessage: '[data-zg-role="refund-message"]',

		// bank details (refund)
		bankDetails: '[data-zg-role="refund-bank-details"]',
		bankDetailsHolder: '[data-zg-role="refund-bank-holder"]',
		bankDetailsIban: '[data-zg-role="refund-bank-iban"]',
		bankDetailsSwift: '[data-zg-role="refund-bank-swift"]'
	};


	/**
	 *
	 *
	 */
	Return.prototype.filterProducts = function () {
		var that = this;

		this.resetValues();

		// remove visual feedback from the products
		$( this.options.productContainer )
			.removeClass( that.options.successClass )
			.removeClass( that.options.dangerClass );

		$( this.options.returnSelector ).each( function () {
			var $this = $( this ),
				$product,
				action = $this.val();

			if ( action ) {
				$product = $this.closest( that.options.productContainer );

				if ( action === 'exchange' ) {
					that.processExchangeProduct( $product );
				} else if ( action === 'refund' ) {
					that.processRefundProduct( $product );
				}
			}
		} );

		// There are products to refund. Validate the bank details (even if the products are invalid)
		if ( this.refundProducts.length || this.showRefundMsg ) {
			this.getBankDetails();
		}

		// no products selected for refund or exchange
		if ( this.enableProceed && !this.exchangeProducts.length && !this.refundProducts.length ) {

			this.enableProceed = false;
			this.showNoItemsMsg = true;

		}

		this.showMessages();

		if ( this.enableProceed ) {
			this.sendRequests();
		}
	};


	/**
	 *
	 * @returns {object}
	 */
	Return.prototype.getBankDetails = function () {
		var $bankDetails, accountHolderName, ibanCode, swiftCode, valid;

		valid = true;
		$bankDetails = $( this.options.bankDetails );

		// try to validate the details form
		if ( $bankDetails.data( 'zgValidator' ) ) {
			valid = $bankDetails.data( 'zgValidator' ).validate();
		}

		ibanCode = $( this.options.bankDetailsIban ).val();
		swiftCode = $( this.options.bankDetailsSwift ).val();
		accountHolderName = $( this.options.bankDetailsHolder ).val();

		this.bankDetails = {};

		if ( valid && ibanCode && swiftCode && accountHolderName ) {

			this.bankDetails = {
				'accountHolderName': accountHolderName,
				'ibanCode': ibanCode,
				'swiftCode': swiftCode
			};

		} else {

			this.enableProceed = false;
			this.showBankMsg = true;
		}

		return this.bankDetails;
	};


	/**
	 *
	 * @param {jQuery} $product
	 */
	Return.prototype.processExchangeProduct = function ( $product ) {
		var skuOld = $product.data( 'sku' ),
			skuNew = $product.data( 'sku-new' );

		if ( skuNew && skuNew != skuOld ) {

			// add selected products to the request
			this.exchangeProducts.push( {
				product_id: $product.data( 'product-id' ),
				sku: skuNew,
				old_sku: skuOld
			} );

			$product
				.removeClass( this.options.dangerClass )
				.addClass( this.options.successClass );

		} else {

			// The no sku was selected or the original one was selected again
			this.enableProceed = false;
			this.showExchangeMsg = true;

			$product
				.removeClass( this.options.successClass )
				.addClass( this.options.dangerClass );
		}
	};


	/**
	 *
	 * @param {jQuery} $product
	 */
	Return.prototype.processRefundProduct = function ( $product ) {
		var status = $product.find( this.options.productStatus ).val();
		var message = $product.find( this.options.productMessage ).val();
		message = message.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ' ');


		if ( status &&  message) {

			// add selected products to the request
			this.refundProducts.push( {
				product_id: $product.data( 'product-id' ),
				sku: $product.data( 'sku' ),
				reasonCode: status,
				reasonDescription: message
			} );

			$product
				.removeClass( this.options.dangerClass )
				.addClass( this.options.successClass );

		} else {

			// no reasons selected
			this.enableProceed = false;
			this.showRefundMsg = true;

			$product
				.removeClass( this.options.successClass )
				.addClass( this.options.dangerClass );
		}
	};


	/**
	 *
	 */
	Return.prototype.resetValues = function () {
		this.exchangeProducts = [];
		this.refundProducts = [];
		this.bankDetails = {};

		this.enableProceed = true;

		this.showRefundMsg = false;
		this.showExchangeMsg = false;
		this.showNoItemsMsg = false;
		this.showBankMsg = false;

		this.sentExchangeRequest = false;
		this.sentRefundRequest = false;
	};


	/**
	 *
	 *
	 */
	Return.prototype.sendRequests = function () {

		this.sentExchangeRequest = false;
		if ( this.exchangeProducts.length ) {
			this.sendExchangeRequest();
		} else {
			this.sentExchangeRequest = true;
		}


		this.sentRefundRequest = false;
		if ( this.refundProducts.length ) {
			this.sendRefundRequest();
		} else {
			this.sentRefundRequest = true;
		}
	};


	/**
	 * @method sendExchangeRequest
	 * @fires document#zg-notification Notification request for a product change has been sent
	 */

	/**
	 * @method sendExchangeRequest
	 * @fires document#zg-error Notification request for a product change could not be sent
	 */

	/**
	 * @method sendExchangeRequest
	 * @fires document#return.sentRequest Request for a product change has been sent
	 */
	Return.prototype.sendExchangeRequest = function () {
		var request, that;


		if ( this.exchangeProducts.length ) {
			that = this;

			// generate request
			request = {
				action: 'changeProducts',
				order_id: this.options.orderId,
				products: this.exchangeProducts
			};

			if ( this.refundProducts.length ) {
				request.change_status = false;
			}

			$.ajax( {
				type: 'post',
				url: window.makeUrl( { module: 'eshop', action: 'changeProducts' } ),
				data: request,

				beforeSend: function () {
                    that.$element.prop( 'disabled', true );
                },
				success: function ( response ) {
					var message;

					if ( DEBUG ) {
						console.log( 'exchange - success', response );
					}

					if ( response.status === 'success' ) {

						$( document ).trigger( 'zg-notification', [{
							eventType: 'refund.sendRequest',
							message: 'Your request for a product change has been sent'
						}] );

						that.sentExchangeRequest = true;

					} else {

						message = response.message || 'Your request for a product change could not be sent';

						if ( _.isArray( message ) ) {
							message = message.join( '<br/>' );
						}

						$( document ).trigger( 'zg-error', [{
							eventType: 'refund.sendRequest',
							message: message
						}] );
					}
				},

				error: function ( response ) {
					if ( DEBUG ) {
						console.log( 'exchange - error', response );
					}

					$( document ).trigger( 'zg-error', [{
						eventType: 'refund-request-sent',
						message: ( response && response.message ? response.message : JS_TRANSLATIONS.genericErrorMsg )
					}] );

                    that.$element.prop( 'disabled', false );
				},

				complete: function () {
					$( document ).trigger( 'return.sentRequest' );
				}
			} );
		}
	};


	/**
	 * @method sendRefundRequest
	 * @fires document#return.sentRequest Request for a product refund has been sent
	 */
	Return.prototype.sendRefundRequest = function () {
		var request, that;


		if ( this.refundProducts.length && !_.isEmpty( this.bankDetails ) ) {
			that = this;

			// generate request
			request = {
				action: 'refundProducts',
				order_id: this.options.orderId,
				products: this.refundProducts,
				bankAccountDetails: this.bankDetails
			};


			$.ajax( {
				type: 'post',
				url: window.makeUrl( { module: 'eshop', action: 'refundProducts' } ),
				data: request,

				beforeSend: function () {
                    that.$element.prop( 'disabled', true );
                },
				success: function ( response ) {
					var message;

					if ( DEBUG ) {
						console.log( 'refund - success', response );
					}

					if ( response.status === 'success' ) {

						$( document ).trigger( 'zg-notification', [{
							eventType: 'refund.sendRequest',
							message: 'Your request for a product refund has been sent'
						}] );

						that.sentRefundRequest = true;

					} else {

						message = ( response.message || 'Your request for a product change could not be sent' );

						if ( _.isArray( message ) ) {
							message = message.join( '<br/>' );
						}

						$( document ).trigger( 'zg-error', [{
							eventType: 'refund.sendRequest',
							message: message
						}] );
					}
				},

				error: function ( response ) {
					if ( DEBUG ) {
						console.log( 'refund - error', response );
					}

					$( document ).trigger( 'zg-error', [{
						eventType: 'refund-request-sent',
						message: ( response.message || JS_TRANSLATIONS.genericErrorMsg )
					}] );

                    that.$element.prop( 'disabled', false );
				},

				complete: function () {
					$( document ).trigger( 'return.sentRequest' );
				}
			} );
		}
	};


	/**
	 *
	 */
	Return.prototype.showBankDetailsForm = function () {
		var $returns = $( this.options.returnSelector ).filter( function () {
			return ( $( this ).val() === 'refund' );
		} );

		if ( $returns.length ) {
			$( this.options.bankDetails )
				.removeClass( 'hidden' )
				.show();
		} else {
			$( this.options.bankDetails ).hide();
		}
	};


	/**
	 *
	 *
	 */
	Return.prototype.showMessages = function () {

		if ( this.showRefundMsg ) {
			$( document ).trigger( 'zg-error', [{
				eventType: 'return.processExchangeRequest',
				message: JS_TRANSLATIONS.return_please_indicate || 'Please indicate the reason why you want a refund'
			}] );
		}

		if ( this.showExchangeMsg ) {
			$( document ).trigger( 'zg-error', [{
				eventType: 'return.processExchangeRequest',
				message: JS_TRANSLATIONS.return_select_valid || 'Please select a valid item'
			}] );
		}

		if ( this.showNoItemsMsg ) {
			$( document ).trigger( 'zg-error', [{
				eventType: 'return.filterProducts',
				message: JS_TRANSLATIONS.return_please_select || 'Please select at least one item'
			}] );
		}

		if ( this.showBankMsg ) {
			$( document ).trigger( 'zg-error', [{
				eventType: 'return.getBankDetails',
				message: JS_TRANSLATIONS.return_bank_details || 'Please provide your bank account details'
			}] );
		}
	};


	/**
	 *
	 *
	 */
	Return.prototype.setEventHandlers = function () {
		var that = this;

		// click on the return button. start the refund/exchange process
		this.$element.on( 'click.zg.return', function ( e ) {
			e.preventDefault();
			that.filterProducts();
		} );

		$( this.options.productMessage ).bind('copy paste', function (e) { 
			e.preventDefault(); 
		});


		/**
		 * @method document
		 * @listen document#return.sentRequest A request has been sent. make sure both are finished and move to the next step
		 */

		$( document ).on( 'return.sentRequest', function () {
			if ( that.sentExchangeRequest && that.sentRefundRequest ) {
				window.location.replace( window.makeUrl( {
					module: 'eshop',
					manager: 'order',
					action: 'view',
					oID: that.options.orderId
				} ) );
			}
		} );


		// -------------------------------------------------------------------------------

		// we use a form to validate the bank details, but we shouldn't submit it
		$( this.options.bankDetails ).on( 'submit', function ( e ) {
			e.preventDefault();
		} );


		// -------------------------------------------------------------------------------


		// Select refund or exchange for a product
		$( this.options.returnSelector ).on( 'change.zg.return', function () {
			var $this = $( this ),
				$product = $this.closest( that.options.productContainer ),
				$actionContainer = $product.find( that.options.returnAction ),
				action = $this.val();

			$product.removeClass( that.options.successClass );
			$actionContainer.hide();

			if ( action ) {
				if ( action === 'exchange' ) {
					that.processExchangeProduct( $product );
				} else if ( action === 'refund' ) {
					that.processRefundProduct( $product );
				}

				// show the actionContainer for the current action
				$actionContainer
					.filter( '.' + action )
					.removeClass( 'hidden' )
					.fadeIn();
			}

			// do not add the dangerClass until the user tries to send the return request
			$product.removeClass( that.options.dangerClass );

			if ( action === 'refund' ) {
				// This product is a refund. Show the bank details form (if present).
				$( that.options.bankDetails )
					.removeClass( 'hidden' )
					.show();
			} else {
				// do a thorough check to hide/show the bank details form
				that.showBankDetailsForm();
			}
		} );


		// -------------------------------------------------------------------------------


		// Remove the danger class when you select a "return reason"
		$( this.options.productStatus ).on( 'change.zg.return', function () {
			var $this = $( this ),
				$product = $this.closest( that.options.productContainer ),
				status = $this.val();

			if ( status ) {
				$product
					.removeClass( that.options.dangerClass )
					.addClass( that.options.successClass );
			}
		} );


		//
		$( that.options.productUpdater ).on( 'click.zg.return', function () {
			var $this = $( this ),
				$product = $this.closest( that.options.productContainer ),
				namespace = $product.data( 'namespace' ) || ('' + new Date().getTime()),
				combination;

			// store the new selected options from the quickedit modal
			$( document )
				.off( 'zg.product.selectedCombination.' + namespace )
				.on( 'zg.product.selectedCombination.' + namespace, function ( e, data ) {
					combination = data.combination;
				} );

			//
			$( document )
				.off( 'zg.product.selectedConfiguration.' + namespace )
				.on( 'zg.product.selectedConfiguration.' + namespace, function ( e, info ) {
					$product.data( 'sku-new', info.sku );

					that.processExchangeProduct( $product );

					// Update selected combination.
					// Show the new selected options from the quickedit modal
					$product.find( that.options.combination )
						.hide()
						.removeClass( 'hidden' )
						.html( handlebarsTemplates.render( 'selected-combination-item', combination ) )
						.fadeIn();
				} );
		} );
	};


	// RETURN PLUGIN DEFINITION
	// ========================

	function Plugin ( option ) {
		return this.each( function () {
			var $this = $( this );
			var data = $this.data( 'zg.return' );
			var options = $.extend( {}, Return.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.return', new Return( this, options ) );
			}
		} );
	}

	$.fn.zgReturn = Plugin;
	$.fn.zgReturn.Constructor = Return;


	// RETURN DATA-API
	// ===============

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery, _ ));
