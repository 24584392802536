/* ============================================================
 * zg-change-product.js
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function ($) { /* global JS_TRANSLATIONS, DEBUG, handlebarsTemplates */
	'use strict';

	// CHANGE CLASS DEFINITION
	// =======================

	var Exchange = function (element, options) {
		this.$element = $(element);
		this.options = options;

		this.setEventHandlers();
	};


	Exchange.DEFAULTS = {
		container:        '[data-zg-role="refund-select"]',

		productSelector:  '[data-zg-role="change-select"]',
		productContainer: '[data-zg-role="change-product"]',
		productStatus:    '[data-zg-role="change-status-code"]',
		productMessage:   '[data-zg-role="change-message"]',
		productUpdater:   '[data-zg-role="quickedit"]',

		combination:      '[data-zg-role="change-combination"]',
		tempCombination:  '[data-zg-role="change-combination-temp"]',

		successClass: 'btn-success',
		errorClass:   'btn-danger',

		url: window.makeUrl({module: 'eshop', action: 'changeProducts'}),
		orderId: null,
	};


	/**
	 *
	 *
	 */
	Exchange.prototype.getProducts = function () {
		var that = this,
			$products = $( this.options.productSelector ).filter(":checked"),
			products = [],
			isReady = true;

		// no products selected for exchange
		if ( !$products.length ) {
			$( document ).trigger('zg-error', [{
				eventType: 'exchange.sendRequest',
				message: JS_TRANSLATIONS.return_please_select || 'Please select at least one item'
			}]);

			return null;
		}


		// add selected products to the request
		$products.each(function(){
			var $product = $(this).closest( that.options.productContainer ),
				oldSku = $product.data( 'sku' ),
				newSku = $product.data( 'sku-new' );

			if ( !newSku /*|| newSku == oldSku */) {
				$( document ).trigger('zg-error', [{
					eventType: 'exchange.sendRequest',
					message: JS_TRANSLATIONS.return_select_valid || 'Please select a valid item'
				}]);

				$product.find( that.options.productUpdater )
					.removeClass("btn-success")
					.addClass("btn-danger");

				isReady = false;
			}

			products.push({
				product_id: $product.data( 'product-id' ),
				sku: newSku,
				old_sku: oldSku
			});
		});

		return isReady ? products : null;
	};


	/**
	 *
	 *
	 */
	Exchange.prototype.sendRequest = function () {
		var that = this,
			request = {},
			products = this.getProducts();

		if ( !products || !products.length ) {
			return false;
		}

		// generate request
		request = {
				action: "changeProducts",
				products: products,
				order_id: this.options.orderId
			};

		$.ajax({
			type : 'post',
			url  : that.options.url,
			data : request,

			beforeSend: function () {
				$( that.options.container ).addClass( 'loading' );
			},

			success: function ( response ) {
				if ( DEBUG ) { console.log( "exchange - success", response ); }

				if ( response.status === 'success' ){
					$(document).trigger('zg-notification', [{
						eventType: 'refund.sendRequest',
						message: 'Your request for a product change has been sent'
					}]);

					window.location.replace( window.makeUrl({module: 'eshop', manager: 'order', action: 'view', 'oID': request.order_id }) );
				} else {
					var message = response.message && response.message.length ? response.message.join("<br>") : 'Your request for a product change could not be sent';

					$(document).trigger('zg-error', [{
						eventType: 'refund.sendRequest',
						message: message
					}]);
				}
			},

			error: function ( response ) {
				if ( DEBUG ) { console.log( "exchange - error", response ); }

				$(document).trigger('zg-error', [{
					eventType: 'refund-request-sent',
					message: ( response && response.message ? response.message : JS_TRANSLATIONS.genericErrorMsg )
				}]);
			},

			complete: function () {
				$( that.options.container ).removeClass( 'loading' );
			}
		});
	};


	/**
	 *
	 *
	 */
	Exchange.prototype.setEventHandlers = function () {
		var that = this;

		//
		this.$element.on('click.zg.exchange', function ( e ) {
			e.preventDefault();
			that.sendRequest();
		});

		//
		$( this.options.productSelector )
			.on('change.zg.exchange', function () {

				// Find and then enable or disable the button
				$(this)
					.closest( that.options.productContainer )
					.find( that.options.productUpdater )
					.prop( 'disabled', !this.checked );

			})
			.change(); // trigger the event so the button is updated

		//
		$( that.options.productUpdater )
			.on('click.zg.exchange', function () {
				var $this = $(this),
					$product = $this.closest( that.options.productContainer ),
					namespace = $product.data('namespace') || ("" + new Date().getTime()),
					combination;

				// store the new selected options from the quickedit modal
				$(document)
					.off( 'zg.product.selectedCombination.' + namespace )
					.on(  'zg.product.selectedCombination.' + namespace, function ( e, data ) {
						combination = data.combination;
					});

				//
				$(document).one('zg.product.selectedConfiguration.' + namespace, function ( e, info ) {
					$product.data( 'sku-new', info.sku );

					// Update selected combination.
					// show the new selected options from the quickedit modal
					$product.find( that.options.combination )
						.hide()
						.removeClass( 'hidden' )
						.html(
							handlebarsTemplates.render(
								"selected-combination-item",
								combination
							)
						)
						.fadeIn();
				});

				//
				$( $this.data( 'target' ) )
					.off('hidden.bs.modal.exchange')
					.one('hidden.bs.modal.exchange', function () {
						if ( !$product.data( 'sku-new' ) ) {
							$this
								.removeClass('btn-success')
								.addClass('btn-danger');
						} else {
							$this
								.removeClass('btn-danger')
								.addClass('btn-success');
						}
					});

			});
	};


	// CHANGE PLUGIN DEFINITION
	// ========================

	function Plugin( option ) {
		return this.each(function () {
			var $this  = $(this);
			var data   = $this.data('zg.exchange');
			var options = $.extend({}, Exchange.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option);

			if (!data) {
				$this.data('zg.exchange', (data = new Exchange(this, options)));
			}
		});
	}

	$.fn.zg_exchange             = Plugin;
	$.fn.zg_exchange.Constructor = Exchange;


	// CHANGE DATA-API
	// ===============

	$(function () {
		$('[data-zg-role="exchange"]').each( function () {
			Plugin.call( $(this) );
		});
	});

}( jQuery ));