/**
 *
 * When edit  address in checkout, show (and make required) or hide invoice data
 * @author Fabio Polizzi  <dpocina[at]kooomo[dot]com>
 *
 */


(function ( $ ) {
	'use strict';

	/**
	 * @selector data-zg-role="request-invoice" When the SELECTOR (checkbox) change value, we call the function changeInvoiceStatus with value of the checkbox
	 */
	var SELECTOR      = '[data-zg-role="request-register"]',
		invoiceDataContainer = '[data-zg-role="register-password-container"]',
		invoiceDataSelector  = '.form-control',
		containerAddress2  = '[data-zg-role="container-address-2"]',
		btnAddress2 = '[data-zg-role="add-address-2"]';
		//electronicInvoicing= '[data-invoicing="electronic-invoicing"]';

	function initRegisterStatus() {
		$( SELECTOR ).each( function () {
			changeRegisterStatus( this );
			//$( invoiceCountrySelector ).trigger( 'change.zg.country');
		} );
	}

	function changeRegisterStatus ( item ) {

		var $selector  = $( item ),
			$form      = $selector.closest( 'form' ),
			$container = $form.find( invoiceDataContainer ),
			$input     = $container.find( invoiceDataSelector );

		if ( $selector.is( ':checked' ) ) {
			$container.removeClass( 'hidden' ).fadeIn();
			$input.prop( 'required', true ).prop( 'disabled', false );
			$('[name="registration_type"]').val('Register');

		} else {
			$container.hide();
			$input.prop( 'required', false ).prop( 'disabled', true );
			$('[name="registration_type"]').val('Guest');
		}
	}


	$( function () {
		$( document ).on( 'change.zg.address', SELECTOR, function () {
     		changeRegisterStatus( this );
  		} );
		$( document ).on( 'zg.addressForm.ready', initRegisterStatus );
		initRegisterStatus();

		if( $( containerAddress2 + " .form-control" ).val() ){
			$( btnAddress2 ).closest( '.form-group' ).hide();
		}else{
			$( containerAddress2 ).hide();
		}
		
		$( document ).on( 'click.zg.address', btnAddress2, function () {
     		$( containerAddress2 ).show();
			$( this ).closest( '.form-group' ).hide();
  		} );

	} );

})( jQuery );
