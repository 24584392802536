/**
 * zg-evoucher.js
 *
 * Plugin for manage e-voucher and use in cart

 *
 * @author: @author: Andrea Basile <abasile [at] koooomo [dot] com>
 *
 */


/**
 * @event document#evoucher-getInfo get the data of e-voucher
 * @type {object}
 * @property {int} evoucherCode
 * @property {int} usableBalance
 * @property {string} currency
 */

/**
 * @event document#evoucher-use use points to discount
 * @type {object}
 * @property {int} cardUseVal
 * @property {int} cardCode
 */

/**
 * @event document#evoucher-delete-usage delete used points
 * @type {object}
 * @property {int} cardCode
 */


/* global  JS_TRANSLATIONS */

(function ($, _) {

    'use strict';

    var root = this;

    /**
     * @selector data-zg-role="evoucher" The plugin start if there is the selector in the dom when the page load
     */

    var selector = '[data-zg-role="evoucher"]';

    /**
     * * @param {int} [cardCode] the id of e-voucher
     * * @param {int} [cardUseVal] value of discount used
     * * @param {int} [totalCart] total in the cart
     * * @param {string} [getEvoucherInfo] selector to get info
     * * @param {int} [use_evoucher] selector to use points
     * * @param {int} [delete_evoucher_btn] selector to delete the discount
     * * @param {int} [evoucherCode] selector for evoucher code
     * * @param {int} [cardUseValSelector] selector for usable points
     * * @param {int} [usableBalanceSelector] selector for usable balance points
     * * @param {int} [currencySelector] cuirrency selector
     * * @param {int} [totalCartSelector] selector for tortal amount in cart
     * * @param {int} [boxInfoCardSelector] info box selector
     *
     */

    var DEFAULTS = {
        //DATA
        cardCode:0,
        cardUseVal:0,
        totalCart:0,
        //SELECTOR
        getEvoucherInfo:        '[data-text-form="get-evoucher-info"]',
        use_evoucher:           '[data-zg-role="use-evoucher"]',
        delete_evoucher_btn:    '[data-zg-role="delete-evoucher-btn"]',
        evoucherCode :          '[data-zg-role="evoucher-code"]',
        cardUseValSelector:     '[data-zg-role="evoucher-usable-value"]',
        usableBalanceSelector : '[data-zg-role="usable-balance"]',
        currencySelector :      '[data-zg-role="currency"]',
        totalCartSelector :     '[data-zg-role="total-cart"]',
        boxInfoCardSelector :   '[data-zg-role="box-info-card"]'
    };

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var Evoucher = function ( element, options ) {
        this.$element                   = $( element );
        this.options                    = _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.$getEvoucherInfo           = this.$element.find( this.options.getEvoucherInfo );
        this.$use_evoucher              = this.$element.find( this.options.use_evoucher );
        this.$delete_evoucher_btn       = this.$element.find( this.options.delete_evoucher_btn );
        this.$evoucherCode              = this.$element.find( this.options.evoucherCode );
        this.$cardUseVal                = this.$element.find( this.options.cardUseValSelector );
        this.$usableBalance             = this.$element.find( this.options.usableBalanceSelector );
        this.$currency                  = this.$element.find( this.options.currencySelector );
        this.$boxInfoCard               = this.$element.find( this.options.boxInfoCardSelector );


        // initialize object
        this.__eventHandlers();
    };

    /**
     * DONE FOR HTML
     * @method getevoucherInfo
     * @fires document#zg-error Display the error if ajax call for evoucher card info not return success
     */

    Evoucher.prototype.getEvoucherInfo = function () {

        var url = window.makeUrl({module: 'eshop', manager: 'giftcard', action: 'getGiftCardInfo'});
        this.options.cardCode =  this.$element.find( this.$evoucherCode).val();
        this.$boxInfoCard.addClass('hidden');
        this.$element.addClass('loading');
        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            data: { 'cardCode': this.options.cardCode },
            success:  _.bind( function ( response ) {
                if (response && response.status === 'success'){
                    this.$evoucherCode.html(this.options.cardCode);
                    this.$cardUseVal.html(response.data.saldo);
                    this.$usableBalance.html(response.data.balance);
                    this.$currency.html(response.data.currency);
                    this.$boxInfoCard.removeClass('hidden');
                }else {
                    $(document).trigger('zg-error', [{
                        eventType: 'evoucher-getInfo',
                        message: response.message || JS_TRANSLATIONS.genericErrorMsg
                    }]);
                }
                this.$element.removeClass('loading');
            }, this ),
            error:  _.bind( function (  ) {
                this.$element.removeClass('loading');
                $(document).trigger('zg-error', [{
                    eventType: 'evoucher-getInfo',
                    message: JS_TRANSLATIONS.genericErrorMsg
                }]);
            }, this )
        });
    };

    /**
     * DONE FOR HTML
     * @method use_evoucher
     * @fires document#zg-error Display the error evoucher card point used are more of the point in the card, or more of the total, or less of 0
     */
    Evoucher.prototype.use_evoucher_card = function () {
        this.$element.addClass('loading');
        this.options.cardUseVal=this.$element.find(this.$cardUseVal).val();
        this.options.cardCode=this.$element.find(this.$evoucherCode).val();
        if(this.options.cardUseVal <= 0){
            this.$element.removeClass('loading');
            $(document).trigger('zg-error', [{
                eventType: 'evoucher-use',
                message: JS_TRANSLATIONS['external.giftcard.invalid.usage1']
            }]);
        } else if(this.options.cardUseVal > this.options.totalValue){
            this.$element.removeClass('loading');
            $(document).trigger('zg-error', [{
                eventType: 'evoucher-use',
                message: JS_TRANSLATIONS['external.giftcard.invalid.bal']
            }]);
        } else if(this.options.cardUseVal > this.options.totalCart) {
            this.$element.removeClass('loading');
            $(document).trigger('zg-error', [{
                eventType: 'evoucher-use',
                message: JS_TRANSLATIONS['external.giftcard.invalid.usage']
            }]);
        } else{
            var url = window.makeUrl({module: 'eshop', manager: 'giftcard', action: 'useGiftCardInfo'});
            $.ajax({
                type: 'post',
                url: url,
                dataType: 'json',
                data: { cardCode: this.options.cardCode , cardUseVal:this.options.cardUseVal},
                success:  _.bind( function ( response ) {
                    if (response && response.status === 'success'){
                        location.reload();
                    } else {
                        this.$element.removeClass('loading');
                        $(document).trigger('zg-error', [{
                            eventType: 'evoucher-use',
                            message: response.message || JS_TRANSLATIONS.genericErrorMsg
                        }]);
                    }
                },this),
                error:  _.bind( function (  ) {
                    this.$element.removeClass('loading');
                    $(document).trigger('zg-error', [{
                        eventType: 'evoucher-use',
                        message: JS_TRANSLATIONS.genericErrorMsg
                    }]);
                },this)
            });
        }
    };

    /**
     * DONE FOR HTML
     * @method delete_evoucher
     * @fires document#zg-error Display the error if ajax call for delete evoucher card not response success
     */

    Evoucher.prototype.delete_evoucher_card = function () {
        var url = window.makeUrl({module: 'eshop', manager: 'giftcard', action: 'deleteGiftCardUsage'});
        $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            data: { cardCode: this.options.cardCode},
            success:_.bind( function ( response ) {
                if (response && response.status === 'success'){
                    location.reload();
                } else {
                    $(document).trigger('zg-error', [{
                        eventType: 'evoucher-delete-usage',
                        message: response.message || root.JS_TRANSLATIONS.genericErrorMsg
                    }]);
                }
            },this),
            error: _.bind( function (  ) {
                $(document).trigger('zg-error', [{
                    eventType: 'evoucher-delete-usage',
                    message: root.JS_TRANSLATIONS.genericErrorMsg
                }]);
            },this)
        });
    };

    /**
     * @method __eventHandlers
     * @listen document#submit.zg.comment. On submit adds the comment to product
     */
    /**
     * @method __eventHandlers
     * @listen document#click.zg.comment. read more or less comment depends if btn clicked has more/less mode value
     *
     */

    Evoucher.prototype.__eventHandlers = function () {

        this.$getEvoucherInfo.on('submit.zg.evoucher', (function ( event ) {
            event.preventDefault();
            this.getEvoucherInfo();
        }).bind( this ) );

        this.$use_evoucher.on('submit.zg.evoucher', (function ( event ) {
            event.preventDefault();
            this.use_evoucher_card();
        }).bind( this ) );

        this.$delete_evoucher_btn.on('click.zg.evoucher', (function ( event ) {
            event.preventDefault();
            this.delete_evoucher_card();
        }).bind( this ) );



    };

    // Evoucher PLUGIN DEFINITION
    // =========================
    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.evoucher' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.evoucher', new Evoucher( this, options ) );
            }
        } );
    }

    $.fn.zg_evoucher             = Plugin;
    $.fn.zg_evoucher.Constructor = Evoucher;

    //START PLUGIN
    $( function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );


}).call( this, jQuery, _ );
